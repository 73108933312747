<template>
    <HorizontalContentSlider
        v-if="data && data.items.length > 0"
        :items="data.items"
        class="my-xxl"
    >
        <template v-slot:item="{ item }">
            <div class="impactstep flex flex-col rounded-m p-l">
                <div class="impactstep__img mb-xl">
                    <ImageClip v-if="item.image" :image="cdnurl(item.image)"/>
                </div>
                <div class="impactstep__text">
                    <h4>{{ item.name }}</h4>
                    <div class="color-secondary" v-html="$options.filters.nl2br(item.text_en)" />
                </div>
            </div>
        </template>
    </HorizontalContentSlider>
</template>

<script>
import HorizontalContentSlider from '@/components/ui/HorizontalContentSlider.vue';
import ImageClip from '@/components/ImageClip.vue';

export default {
    name: 'ImpactSteps',
    components: {
        HorizontalContentSlider,
        ImageClip,
    },
    props: {
        data: Object,
    },
};
</script>

<style>
.impactstep {
    --width: 32rem;
    border: 0.3rem solid var(--color-accent-light);
    width: var(--width);
    position: relative;
    min-height: 100%;
}
    .impactstep__img {
        margin-left: auto;
        margin-right: auto;
        height: calc(var(--width) * 0.6);
        width: calc(var(--width) * 0.6);
        text-align: center;
    }
    .hzc__scroller {
        padding-left: var(--width-xxl-sidepadding) !important;
    }
    .hzc__scroller > li:first-child { margin-left: var(--container-gap); }

.hzc__scroller li .impactstep::before,
.hzc__scroller li .impactstep::after {
    content: '';
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background: var(--color-accent-light);
    border: 0.1rem solid var(--color-accent);
    position: absolute;
}
    .hzc__scroller li:nth-child(2n) .impactstep::before {
        top: var(--size);
        left: var(--size);
    }
    .hzc__scroller li:nth-child(2n + 1) .impactstep::before {
        bottom: var(--size);
        left: var(--size);
    }
    .hzc__scroller li:nth-child(2n + 1) .impactstep::after {
        top: var(--size);
        right: var(--size);
    }
    .hzc__scroller li:nth-child(2n) .impactstep::after {
        bottom: var(--size);
        right: var(--size);
    }
    .hzc__scroller li:first-child .impactstep::before,
    .hzc__scroller li:last-child .impactstep::after {
        display: none;
    }
    .hzc__scroller li:not(:first-child)::before {
        content: '';
        width: 4.7rem;
        height: 4rem;
        border-radius: 2.5rem 2.5rem 0 0 / 1.5rem 1.5rem 0 0;
        border: 0.2rem solid var(--color-accent);
        border-width: 0.2rem 0 0 0;
        position: absolute;
        top: -0.2rem;
        right: calc(calc(100% - var(--size)) - 0.8rem);
        z-index: 5;
        display: block;
    }
    .hzc__scroller li:nth-child(2n + 1)::before {
        border-radius: 0 0 2.5rem 2.5rem / 0 0 1.5rem 1.5rem;
        border-width: 0 0 0.2rem 0;
        bottom: -0.2rem;
        top: auto;
    }
</style>

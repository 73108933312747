<template>
    <div class="c w-xl flex flex-wrap align-center justify-between">
        <div class="header__text" :class="textClass[type]">
            <h1 class="w-10 color-primary leading-xs mb-r" :class="title_class" v-html="$options.filters.titleContrast(title)" />
            <div class="color-secondary" :class="text_class" v-html="$options.filters.nl2br(text)" />
            <Actions
                v-if="actions"
                :actions="actions"
                class="mt-l"
            />
        </div>
        <div class="header__image" :class="imageClass[type]">
            <slot name="right">
                <div v-if="image" class="ratio-3by2 rounded-m shadow-l overflow-hidden">
                    <img v-lazy="image" :alt="image_title || title" class="ratio-stretch object-cover" />
                </div>
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Header',
    props: {
        title: String,
        title_class: String,
        text: String,
        text_class: String,
        actions: Array,
        image: String,
        image_title: String,
        type: {
            type: String,
            default: 'small',
        },
    },
    data() {
        return {
            textClass: {
                large: 'w-12 m:w-7 l:w-5 xl:w-5 s-down:py-xxl',
                small: 'w-12 m:w-7 l:w-5 xl:w-6 s-down:py-xxl',
            },
            imageClass: {
                large: 'w-12 s-down:mt-xl m:w-5 l-up:w-5',
                small: 'w-12 s-down:mt-xl m:w-5 l-up:w-4',
            },
        };
    },
};
</script>

<style>

</style>

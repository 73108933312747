<template>
    <div class="impact relative text-center">

        <div class="c w-m impact__cont relative bg-accent-light rounded-m overflow-hidden">

            <div class="impact__text text-center flex flex-col align-center py-xxl my-l mx-auto">
                <IntroText
                    class="text-center"
                    :title="data.stats_title_en"
                    :text="data.stats_text_en"
                />

                <div class="impact__stats flex flex-wrap text-center justify-center size-6 bg-white shadow-l px-r pt-m mt-l">
                    <div
                        class="impact__stat leading-0 flex align-center text-left mx-m mb-m"
                        v-for="(n, index) in ['Check', 'PlusOutline', 'Tree']"
                        :key="n"
                    >
                        <div class="icon rounded-50 bg-accent-light w-48 h-48 flex align-center justify-center">
                            <component :is="n + 'Icon'" :size="28" />
                        </div>
                        <div class="ml-m color-secondary">
                            <div class="color-primary weight-b font-title size-4 mb-s">{{ data['stat_' + (index + 1) + '_amount'] }}</div>
                            {{ data['stat_' + (index + 1) + '_name_en'] }}
                        </div>
                    </div>
                </div>

                <Actions :actions="actions" class="mt-l" />
            </div>
        </div>

        <template v-for="i in 8">
            <img
                v-if="data['stats_image_' + i]"
                v-lazy="cdnurl(data['stats_image_' + i])"
                :key="i"
                class="impact__image"
            />
        </template>

    </div>
</template>

<script>
import CheckIcon from 'vue-material-design-icons/Check.vue';
import PlusOutlineIcon from 'vue-material-design-icons/PlusOutline.vue';
import TreeIcon from 'vue-material-design-icons/Tree.vue';

export default {
    name: 'Impact',
    components: {
        CheckIcon,
        PlusOutlineIcon,
        TreeIcon,
    },
    computed: {
        data() {
            return this.$store.getters.data;
        },

        actions() {
            const list = [];
            if (this.data.stats_button_1_link && this.data.stats_button_1_title_en) {
                list.push({
                    title: this.data.stats_button_1_title_en,
                    href: this.data.stats_button_1_link,
                    class: 'shadow-m bg-accent',
                });
            }
            if (this.data.stats_button_2_link && this.data.stats_button_2_title_en) {
                list.push({
                    title: this.data.stats_button_2_title_en,
                    href: this.data.stats_button_2_link,
                    class: 'shadow-m color-accent',
                });
            }
            return list;
        },
    },
};
</script>

<style>
    .impact__cont::after {
        content: '';
        border-radius: var(--radius-m);
        border: 0.3rem solid var(--color-accent);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 10;
    }

    .impact__image {
        --impact-size: 13vw;
        width: var(--impact-size);
        height: var(--impact-size);
        border-radius: var(--radius-m);
        border: 0.6rem solid white;
        box-shadow: var(--shadow-m);
        position: absolute;
        top: 50%;
        left: 0;
    }
        .impact__image:nth-child(3n + 2) {
            transform: rotate(7deg);
        }
        .impact__image:nth-child(3n + 1) {
            transform: rotate(-4deg);
        }
        .impact__image:nth-child(3n + 3) {
            transform: rotate(-7deg);
        }

        @media (min-width: 75em) {
            .impact__image:nth-child(2),
            .impact__image:nth-child(6) {
                margin-top: calc(var(--impact-size) * -0.85);
            }
            .impact__image:nth-child(3),
            .impact__image:nth-child(7) {
                margin-top: calc(var(--impact-size) * 0.05);
            }
            .impact__image:nth-child(4),
            .impact__image:nth-child(8) {
                margin-top: calc(var(--impact-size) * -0.9);
            }
            .impact__image:nth-child(5),
            .impact__image:nth-child(9) {
                margin-top: 0;
            }

            .impact__image:nth-child(2) {
                left: calc(var(--impact-size) * -0.15);
            }
            .impact__image:nth-child(3) {
                left: 0;
            }
            .impact__image:nth-child(4) {
                left: calc(var(--impact-size) * 0.7);
            }
            .impact__image:nth-child(5) {
                left: calc(var(--impact-size) * 0.85);
            }

            .impact__image:nth-child(6) {
                left: calc(100vw - calc(var(--impact-size) * 0.85));
            }
            .impact__image:nth-child(7) {
                left: calc(100vw - var(--impact-size));
            }
            .impact__image:nth-child(8) {
                left: calc(100vw - calc(var(--impact-size) * 1.7));
            }
            .impact__image:nth-child(9) {
                left: calc(100vw - calc(var(--impact-size) * 1.9));
            }
        }

        @media (min-width: 62em) and (max-width: 74.9em) {
            .impact__image:nth-child(2),
            .impact__image:nth-child(6) {
                margin-top: calc(var(--impact-size) * -1.45);
            }
            .impact__image:nth-child(3),
            .impact__image:nth-child(7) {
                margin-top: calc(var(--impact-size) * 0.05);
            }
            .impact__image:nth-child(4),
            .impact__image:nth-child(8) {
                margin-top: calc(var(--impact-size) * -0.9);
            }
            .impact__image:nth-child(5),
            .impact__image:nth-child(9) {
                margin-top: calc(var(--impact-size) * 0.7);
            }

            .impact__image:nth-child(2) {
                left: calc(var(--impact-size) * -0.25);
            }
            .impact__image:nth-child(3) {
                left: calc(var(--impact-size) * -0.25);
            }
            .impact__image:nth-child(4) {
                left: calc(var(--impact-size) * 0.1);
            }
            .impact__image:nth-child(5) {
                left: calc(var(--impact-size) * 0.15);
            }

            .impact__image:nth-child(6) {
                left: calc(100vw - calc(var(--impact-size) * 0.65));
            }
            .impact__image:nth-child(7) {
                left: calc(100vw - calc(var(--impact-size) * 0.75));
            }
            .impact__image:nth-child(8) {
                left: calc(100vw - calc(var(--impact-size) * 1.4));
            }
            .impact__image:nth-child(9) {
                left: calc(100vw - calc(var(--impact-size) * 1.35));
            }
        }

        @media (min-width: 62em) {
            .impact__text {
                width: 90%;
                max-width: 59rem;
            }
            .impact__text h2 { width: 80%; }
        }
        @media (max-width: 61.9em) {
            .impact__image:nth-child(n + 6) {
                display: none;
            }
            .impact__image {
                --impact-size: 20vw;
                pointer-events: none;
                position: relative;
                margin-top: calc(var(--impact-size) * -0.1);
            }

            .impact__cont::after {
                border-radius: 0;
                border-left-width: 0;
                border-right-width: 0;
            }
        }

        @media (max-width: 48em) {
            .impact__image:nth-child(n + 5) {
                display: none;
            }
            .impact__image {
                --impact-size: 25vw;
                margin-top: calc(var(--impact-size) * -0.15);
            }
        }

        @media (max-width: 34em) {
            .impact__image:nth-child(n + 5) {
                display: none;
            }
            .impact__image {
                --impact-size: 30vw;
                border-width: 0.4rem;
                margin-top: calc(var(--impact-size) * -0.15);
            }
        }

        .impact__stats {
            border-radius: 3.6rem;
        }
</style>

<template>
    <div>
        <WaveDownBG>
            <slot name="navigation" />
            <Header
                :title="data.intro_title_en"
                :text="data.intro_text_en"
                :image="cdnurl(data.intro_image)"
                :image_title="data.intro_image_caption"
                type="large"
                :actions="actions"
            />
        </WaveDownBG>

        <div class="textblocks c w-m mb-xxl pb-xxl">
            <Textblock
                v-for="(block, index) in textblocks_home"
                :key="block.id"
                :index="index"
                :data="block"
            />
        </div>

        <Impact class="mb-xxl" />

        <br />

        <div class="c w-xxl mt-xxl pt-xxl">
            <div class="w-12 s:w-10 m:w-8 l:w-6 xl:w-4">
                <IntroText
                    :title="data.impactsteps_title_en"
                    :text="data.impactsteps_text_en"
                    :actions="data.impactsteps_button_title_en && data.impactsteps_button_link ? [{
                        href: data.impactsteps_button_link,
                        class: 'color-accent shadow-m',
                        title: data.impactsteps_button_title_en
                        }] : []
                    "
                />
            </div>
        </div>
        <ImpactSteps
            v-if="data.foundation_impactsteps && data.foundation_impactsteps.length > 0"
            :data="data.foundation_impactsteps[0]"
        />
    </div>
</template>

<script>
import WaveDownBG from '@/components/bgs/WaveDown.vue';
import Header from '@/components/Header.vue';
import Impact from '@/components/Impact.vue';
import ImpactSteps from '@/components//ImpactSteps.vue';
import Textblock from '@/components/ui/Textblock.vue';

export default {
    name: 'Home',
    components: {
        WaveDownBG,
        Header,
        Impact,
        ImpactSteps,
        Textblock,
    },
    computed: {
        data() {
            return this.$store.getters.data;
        },

        actions() {
            if (this.data.intro_button_link && this.data.intro_button_title_en) {
                return [{
                    title: this.data.intro_button_title_en,
                    href: this.data.intro_button_link,
                    class: 'shadow-m color-accent',
                }];
            }
            return [];
        },

        textblocks_home() {
            if (this.data && this.data.foundation_textblocks) {
                return this.data.foundation_textblocks.filter((it) => it.section === 'Home');
            }
            return [];
        },
    },
};
</script>

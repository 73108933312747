<template>
    <div class="hzc">
        <ul class="hzc__scroller" ref="hzc__scroller">
            <li
                v-for="(item, index) in items"
                :key="'hzc_' + index"
                :ref="'hzc__item_' + index"
            >
                <slot name="item" v-bind="{ item, index }">
                    <img
                        v-if="item.image"
                        v-lazy="item.image"
                    />
                </slot>

                <template v-if="arrows">
                    <InViewTrigger class="hzc__intersect" v-if="index === 0" @intersect-change="firstItemVisible = $event" />
                    <InViewTrigger class="hzc__intersect" v-if="index === items.length - 1 && index !== 0" @intersect-change="lastItemVisible = $event" />
                </template>
            </li>
        </ul>

        <template v-if="arrows">
            <ChevronLeftIcon v-if="!firstItemVisible" @click="scrollPrevious" :size="60" class="hzc__button--left color-accent hover:color-primary cursor-pointer z-5" />
            <ChevronRightIcon v-if="!lastItemVisible" @click="scrollNext" :size="60" class="hzc__button--right color-accent hover:color-primary cursor-pointer z-5" />
        </template>
    </div>
</template>

<script>
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue';
import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue';

export default {
    name: 'HorizontalContentSlider',
    components: {
        InViewTrigger: () => import('../helpers/InViewTrigger.vue'),
        ChevronRightIcon,
        ChevronLeftIcon,
    },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        arrows: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            firstItemVisible: true,
            lastItemVisible: true,
        };
    },
    methods: {
        scrollPrevious() { this.scroll('prev'); },
        scrollNext() { this.scroll('next'); },
        scroll(dir) {
            const firstItem = this.$refs.hzc__item_0[0];
            const firstItemWidth = firstItem.offsetWidth;
            const scroller = this.$refs.hzc__scroller;
            const newScrollPos = dir === 'next' ? scroller.scrollLeft + firstItemWidth : Math.max(scroller.scrollLeft - firstItemWidth, 0);
            scroller.scrollTo({
                top: 0,
                left: newScrollPos,
                behavior: 'smooth',
            });
        },
    },
};
</script>

<style>
    .hzc {
        --hzc-gap: var(--size);
        --hzc-max-item-width: calc(100vw - calc(var(--container-gap) * 2));
        position: relative;
    }
    .hzc__scroller {
        width: 100%;
        display: flex;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        scroll-snap-type: x mandatory;
        margin: 0;
        padding: var(--size-xxl) 0;
        scroll-padding: 0;
        position: relative;
        z-index: 1;
        -ms-overflow-style: none;
    }
        .hzc__scroller::-webkit-scrollbar {
            display: none;
        }
        .hzc__scroller > li {
            scroll-snap-align: center;
            padding: 0 calc(var(--hzc-gap) * 2) 0 0;
            position: relative;
        }
        .hzc__scroller > li > * {
            max-width: var(--hzc-max-item-width);
            max-height: var(--hzc-max-item-height, auto);
        }
        .hzc__scroller > li:last-child { padding-right: 0; }
        .hzc__intersect {
            position: absolute;
            left: 50%;
            top: 50%;
        }
    .hzc__button--right,
    .hzc__button--left {
        --hzc-shadow-size: var(--size-s);
        position: absolute;
        top: 50%;
        z-index: 5;
        transform: translateY(-50%);
        right: var(--hzc-gap);
        text-shadow: 0 0 var(--hzc-shadow-size) rgba(0,0,0,0.3);
        transition: var(--transition);
    }
        .hzc__button--left {
            right: auto;
            left: var(--hzc-gap);
        }
        .hzc__button--right:hover,
        .hzc__button--left:hover {
            --hzc-shadow-size: var(--size-m);
        }
</style>
